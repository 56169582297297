<template>
    <div class="container">
        <div class="content">
            <div class="top">
                <div class="titleTop">Welcome</div>
                <img src="@/assets/images/login/taskman.png" alt="">
                <div class="subTitle">高指-智能工厂管理系统</div>
            </div>
            <div class="main">
                <div class="login">忘记密码</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item label="" prop="phone">
                        <el-input v-model.trim="ruleForm.phone" placeholder="请输入手机号">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="" prop="code" class="codeForm">
                        <el-input v-model.trim="ruleForm.code" placeholder="请输入验证码" class="codeLength">
                        </el-input>
                        <el-button @click="getCode" :disabled="codeStatus">{{ codeInfo }}</el-button>
                    </el-form-item>
                    <el-form-item label="" prop="password1">
                        <el-input type="password" v-model.trim="ruleForm.password1" placeholder="请设置新密码" show-password>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="" prop="password2">
                        <el-input type="password" v-model.trim="ruleForm.password2" placeholder="请确认新密码" show-password
                            @keydown.enter.native="onSubmit">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-button class="loginBtn" @click="onSubmit">确 认</el-button>
            </div>
            <div class="footer">
                <div>Copyright © 粤ICP备19011921号</div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { forgetPassword, codeChangePassword } from '@/api/index.js'
export default {
    name: "login",
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '该项不能为空', trigger: 'change' },
                    { min: 11, max: 11, message: '手机号输入格式有误', trigger: 'blur' },
                ],
                code: [
                    {
                        required: true, message: '该项不能为空', trigger: 'change'
                    },
                    {
                        min: 1, max: 14, message: '验证码格式有误', trigger: 'blur'
                    }
                ],
                password1: [
                    {
                        required: true, message: '该项不能为空', trigger: 'change'
                    },
                    {
                        min: 1, max: 14, message: '密码格式有误', trigger: 'blur'
                    }
                ],
                password2: [
                    {
                        required: true, message: '该项不能为空', trigger: 'change'
                    },
                    {
                        min: 1, max: 14, message: '密码格式有误', trigger: 'blur'
                    }
                ]
            },
            ruleForm: {
                phone: '',
                code: '',
                password1: '',
                password2: '',
            },
            codeStatus: false,
            codeInfo: '获取验证码',
        }
    },
    created() {
        if (localStorage.getItem('mark') === 'true') {
            this.codeFn()
        }
    },
    methods: {
        // 获取验证码
        async getCode() {
            if (localStorage.getItem('mark') === 'true') {
                return
            }
            if (this.ruleForm.phone.length !== 11) {
                this.$message.warning('请输入正确的手机号！')
                return
            }
            const res = await codeChangePassword({ phone: String(this.ruleForm.phone) })
            console.log('当前打印:res', res)
            if (res.code === '200') {
                this.$message.success('验证码已发送！')
                localStorage.setItem('s', 60)
                localStorage.setItem('mark', 'true')
                this.codeFn()
            } else {
                this.$message.warning(res.msg)
            }
        },
        // 登录
        onSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    if (this.ruleForm.password1 !== this.ruleForm.password2) {
                        this.$message.warning('两次密码输入不一致！')
                        return
                    } else {
                        let params = {
                            phone: this.ruleForm.phone,
                            newPassword: this.ruleForm.password2,
                            captcha: this.ruleForm.code
                        }
                        const res = await forgetPassword(params)
                        console.log('当前打印:res', res)
                        if (res.code === '200') {
                            this.$message.success('修改密码成功！')
                            this.$router.push('/login')
                        } else {
                            this.$message.error(res.msg)
                        }
                    }
                }
            })
        },
        codeFn() {
            // 避免重复获取二维码
            if (localStorage.getItem('mark') === 'true') {
                let timer = setInterval(() => {
                    this.codeStatus = true
                    this.codeInfo = localStorage.getItem('s') + '秒后重新获取'
                    let temp = Number(localStorage.getItem('s'))
                    temp -= 1
                    localStorage.setItem('s', temp)
                    if (Number(localStorage.getItem('s')) === 0 || Number(localStorage.getItem('s')) < 0) {
                        this.codeStatus = false
                        this.codeInfo = '获取验证码'
                        clearInterval(timer)
                        localStorage.removeItem('s')
                        localStorage.removeItem('mark')
                    }
                }, 1000)
            }
        }
    }
}
</script>
  
<style lang="sass" scoped>
@import './style/password.scss'
</style>